import React, { useEffect, useRef } from "react";
import { Canvas, useThree, useFrame, extend } from "@react-three/fiber";
import { OrbitControls, Line } from "@react-three/drei";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import * as THREE from "three";

import { DoubleSide } from "three";

extend({ TextGeometry });

const getRandom = (min, max) => {
  return Math.random() * (max - min) + min;
};

const Box = () => {
  const boxRef = useRef();

  useFrame(() => {
    boxRef.current.rotation.z += 0.005;
    boxRef.current.rotation.x += 0.005;
  });

  return (
    <mesh ref={boxRef} rotation-x={Math.PI * 0.25} rotation-y={Math.PI * 0.25}>
      <torusKnotGeometry args={[10, 3, 100, 16]} />
      <meshStandardMaterial color={"white"} />
    </mesh>
  );
};

const Gns = () => {
  return (
    <Canvas camera={{ fov: 90, position: [-10, -10, -40] }}>
      <ambientLight intensity={0.6} />
      <spotLight
        position={[10, 10, 10]}
        angle={0.5}
        penumbra={1}
        intensity={0.5}
      />
      <pointLight position={[-10, 10, -10]} intensity={1} />
      <OrbitControls />

      <Box />
    </Canvas>
  );
};

export default Gns;
