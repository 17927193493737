import "./App.css";
import Gns from "./components/Gns";

function App() {
  return (
    <div className="wrapper">
      <div
        style={{
          color: "black",
          display: "flex",
          justifyContent: "center",
          padding: "1rem",
        }}
      >
        <a
          className="mail"
          href="mailto: info@gns.world"
          style={{ color: "black", textDecoration: "none" }}
        >
          info@gns.world
        </a>
      </div>
      <Gns />
    </div>
  );
}

export default App;
